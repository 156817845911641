import {color} from "../constants/color";

const initialState = {
  loading: false,
  colors: [],
  error: null,
};

export function colorReducer(state = initialState, action) {
  switch (action.type) {
    case color.GET_COLOR_PENDING:
      return {
        ...state,
        loading: true,
      };
    case color.GET_COLOR_SUCCESS:
      return {
        ...state,
        loading: false,
        colors: action.payload,
      };
    case color.GET_COLOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case color.ADD_COLOR_PENDING:
      return {
        ...state,
        loading: true,
      };
    case color.ADD_COLOR_SUCCESS:
      return {
        ...state,
        loading: false,
        colors: action.payload,
      };
    case color.ADD_COLOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
      case color.ADD_UPDATE_COLOR_PENDING:
      return {
        ...state,
        loading: true,
      };
    case color.ADD_UPDATE_COLOR_SUCCESS :
      return {
        ...state,
        loading: false,
        colors: action.payload,
      };
    case color.ADD_UPDATE_COLOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case color.DELETE_COLOR_SUCCESS:
      const updatedColor = state.colors.filter(
        (color) => color.id !== action.id
      );
      console.log(state)
      return {
        ...state,
        loading: false,
        colors: updatedColor,
      };
      
      case color.DELETE_COLOR_FAILURE: {
        return {
          ...state,
          loading: false
        }
      }
   
    default:
      return state;
  }
}
