export const product = {
  LIST_HEADING: "Products",
  NEW_PRODUCT: "New PRODUCT",
  GET_PRODUCT_PENDING: "GET_PRODUCT_PENDING",
  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
  GET_PRODUCT_FAILURE: "GET_PRODUCT_FAILURE",
  ADD_PRODUCT_PENDING: "ADD_PRODUCT_PENDING",
  ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
  ADD_PRODUCT_FAILURE: "ADD_PRODUCT_FAILURE",

  GET_DETAIL_REQUEST: "GET_DETAIL_REQUEST",
  GET_DETAIL_SUCCESS: "GET_DETAIL_SUCCESS",

  GET_DETAIL_FAILURE: "GET_DETAIL_FAILURE",
  ADD_MEDIA_REQUEST: 'ADD_MEDIA_REQUEST',
  ADD_MEDIA_SUCCESS: 'ADD_MEDIA_SUCCESS',
  ADD_MEDIA_FAILURE: 'ADD_MEDIA_FAILURE',
  GET_MEDIA_SUCCESS: "GET_MEDIA_SUCCESS",
  GET_MEDIA_FAILURE: "GET_MEDIA_FAILURE",
  ADD_MEDIA_PENDING: "ADD_MEDIA_PENDING",
  DELETE_MEDIA_FAILURE: 'DELETE_MEDIA_FAILURE',
  DELETE_MEDIA_SUCCESS: "DELETE_MEDIA_SUCCESS",
  DELETE_ALLMEDIA_SUCCESS: "DELETE_ALLMEDIA_SUCCESS",
  PRIMARY_MEDIA_REQUEST: 'PRIMARY_MEDIA_REQUEST',
  PRIMARY_MEDIA_SUCCESS: 'PRIMARY_MEDIA_SUCCESS',
  PRIMARY_MEDIA_FAILURE: 'PRIMARY_MEDIA_FAILURE',
  GET_BRAND_SUCCESS: "GET_BRAND_SUCCESS",
  GET_BRAND_FAILURE: "GET_BRAND_FAILURE",
  
  GET_STORE_FAILURE: "GET_STORE_FAILURE",
  GET_STORE_SUCCESS: "GET_STORE_SUCCESS",

  UPDATE_PRODUCT_FAILURE: "UPDATE_PRODUCT_FAILURE",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",

  GET_COLOR_FAILURE: "GET_COLOR_FAILURE",
  GET_COLOR_SUCCESS: "GET_COLOR_SUCCESS",

  ADD_COLOR_MEDIA_REQUEST: 'ADD_COLOR_MEDIA_REQUEST',
  ADD_COLOR_MEDIA_SUCCESS: 'ADD_COLOR_MEDIA_SUCCESS',
  ADD_COLOR_MEDIA_FAILURE: 'ADD_COLOR_MEDIA_FAILURE',

  GET_COLOR_MEDIA_SUCCESS: 'GET_COLOR_MEDIA_SUCCESS',
  GET_COLOR_MEDIA_FAILURE: 'GET_COLOR_MEDIA_FAILURE',
  
  PRIMARY_COLOR_MEDIA_REQUEST: 'PRIMARY_COLOR_MEDIA_REQUEST',
  PRIMARY_COLOR_MEDIA_SUCCESS: 'PRIMARY_COLOR_MEDIA_SUCCESS',
  PRIMARY_COLOR_MEDIA_FAILURE: 'PRIMARY_COLOR_MEDIA_FAILURE',
  
};
