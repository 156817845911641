//Create a redux store so that provider has a store.
import { createStore, applyMiddleware } from "redux";

//Import root reducers that has all reducer
import { rootReducer } from "../reducers";
import reduxPromise from "redux-promise";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";

// defaults to localStorage for web
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = applyMiddleware(reduxPromise, thunk)(createStore)(
  persistedReducer,
);
export const persistor = persistStore(store);
