export const color={
    GET_COLOR_PENDING: "GET_COLOR_PENDING",
    GET_COLOR_SUCCESS: "GET_COLOR_SUCCESS",
    GET_COLOR_FAILURE: "GET_COLOR_FAILURE",

    DELETE_COLOR_SUCCESS: "DELETE_COLOR_SUCCESS",
    DELETE_COLOR_FAILURE: "DELETE_COLOR_FAILURE",

    ADD_COLOR_PENDING: "ADD_COLOR_PENDING",
    ADD_COLOR_SUCCESS: "ADD_COLOR_SUCCESS",
    ADD_COLOR_FAILURE: "ADD_COLOR_FAILURE",

    ADD_UPDATE_COLOR_FAILURE: "ADD_UPDATE_COLOR_FAILURE",
    ADD_UPDATE_COLOR_PENDING: "ADD_UPDATE_COLOR_PENDING",
    ADD_UPDATE_COLOR_SUCCESS: "ADD_UPDATE_COLOR_SUCCESS",

    
  };