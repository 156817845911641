export const brandAndStore = {
    // Brand constants....
    GET_BRAND_PENDING: "GET_BRAND_PENDING",
    GET_BRAND_SUCCESS: "GET_BRAND_SUCCESS",
    GET_BRAND_FAILURE: "GET_BRAND_FAILURE",

    ADD_BRAND_PENDING: "ADD_BRAND_PENDING",
    ADD_BRAND_SUCCESS: "ADD_BRAND_SUCCESS",
    ADD_BRAND_FAILURE: "ADD_BRAND_FAILURE",

    UPDATE_BRAND_FAILURE: "UPDATE_BRAND_FAILURE",
    UPDATE_BRAND_PENDING: "UPDATE_BRAND_PENDING",
    UPDATE_BRAND_SUCCESS: "UPDATE_BRAND_SUCCESS",

    DELETE_BRAND_SUCCESS: "DELETE_BRAND_SUCCESS",
    DELETE_BRAND_FAILURE: "DELETE_BRAND_FAILURE",

    // Store constants...
    GET_STORE_PENDING: "GET_STORE_PENDING",
    GET_STORE_SUCCESS: "GET_STORE_SUCCESS",
    GET_STORE_FAILURE: "GET_STORE_FAILURE",

    ADD_STORE_PENDING: "ADD_STORE_PENDING",
    ADD_STORE_SUCCESS: "ADD_STORE_SUCCESS",
    ADD_STORE_FAILURE: "ADD_STORE_FAILURE",

    UPDATE_STORE_FAILURE: "UPDATE_STORE_FAILURE",
    UPDATE_STORE_PENDING: "UPDATE_STORE_PENDING",
    UPDATE_STORE_SUCCESS: "UPDATE_STORE_SUCCESS",

    DELETE_STORE_SUCCESS: "DELETE_STORE_SUCCESS",
    DELETE_STORE_FAILURE: "DELETE_STORE_FAILURE",

  };