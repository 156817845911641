import { emailSubscription } from "../constants/email";

const initialState = {
  loading: false,
  emails: [],
  error: null,
};

export function emailReducer(state = initialState, action) {
    switch (action.type) {
        case emailSubscription.GET_EMAIL_PENDING:
            return {
                ...state,
                loading: true,
            };

        case emailSubscription.GET_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                emails: action.payload,
            };

        case emailSubscription.GET_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case emailSubscription.DELETE_EMAILS_SUCCESS:
            const updatedEmails = state.emails.filter(
                (email) => email.id !== action.id
            );
            return {
                ...state,
                loading: false,
                emails: updatedEmails,
            };
            
        case emailSubscription.DELETE_EMAILS_FAILURE: {
            return {
            ...state,
            loading: false
            }
        }
        
        default:
            return state;
        }
  }
  

