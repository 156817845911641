import "./App.css";
import { Router, Route } from "react-router-dom";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import history from "./helpers/history";
import { useSelector } from "react-redux";

//Pages
const Login = React.lazy(() => import("./pages/Login/Login"));
const AdminLayout = React.lazy(() => import("./pages/AdminLayout/AdminLayout"));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function App() {
  const authState = useSelector((state) => state.auth);
  return (
    <>
      <Router history={history}>
        <React.Suspense fallback={loading}>
          <Route path="/">
            {authState?.auth === true ? <AdminLayout /> : <Login />}
          </Route>
        </React.Suspense>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
