import { authConstant } from "../constants/auth";
const initState = { auth: false,
  submitted: false,
  userDetails: {},
  userList: {},
  loading: false
};

export const authReducer = (state = initState, action) => {
  switch (action.type) {
    case authConstant.SIGNIN_REQUEST:
      return { ...state, submitted: true };
      break;

    case authConstant.SIGNIN_SUCCESS:
      return { auth: true, userDetails: action.payload };
      break;

    case authConstant.SIGNIN_FAILURE:
      return { ...state, submitted: false };
      break;

    case authConstant.SIGNOUT_SUCCESS:
      return { ...initState, auth: false };
      break;
    case authConstant.SIGNOUT_FAILURE:
      return { ...state, auth: true };
      break;

    case authConstant.USERS_REQUEST:
      return {
        ...state, 
        loading: true 
      };
      break;

    case authConstant.USERS_SUCCESS:
      return { 
        auth: true, 
        loading: false,
        userList: action.payload 
      };
      break;

    case authConstant.USERS_FAILURE:
      return {
        ...state, 
        loading: false 
      };
      break;
    default:
      return state;
      break;
  }
};
