import { category } from "../constants";

const initialState = {
  loading: false,
  categories: [],
  error: null,
};

export function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case category.GET_CATEGORY_PENDING:
      return {
        ...state,
        loading: true,
      };
    case category.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    case category.GET_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case category.ADD_CATEGORY_PENDING:
      return {
        ...state,
        loading: true,
      };
    case category.ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    case category.ADD_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
