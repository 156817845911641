import { brandAndStore } from "../constants/brandAndStore";

const initialState = {
    loading: false,
    brandList: [],
    storeList: [],
    error: null,
};

export function brandAndStoreReducer(state = initialState, action) {
    switch (action.type) {
        case brandAndStore.GET_BRAND_PENDING:
            return {
                ...state,
                loading: true,
            };
        case brandAndStore.GET_BRAND_SUCCESS:
            return {
                ...state,
                loading: false,
                brandList: action.payload,
            };
        case brandAndStore.GET_BRAND_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case brandAndStore.ADD_BRAND_PENDING:
            return {
                ...state,
                loading: true,
            };
        case brandAndStore.ADD_BRAND_SUCCESS:
            return {
                ...state,
                loading: false,
                brandList: action.payload,
            };
        case brandAndStore.ADD_BRAND_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case brandAndStore.UPDATE_BRAND_PENDING:
            return {
                ...state,
                loading: true,
            };
        case brandAndStore.UPDATE_BRAND_SUCCESS:
            return {
                ...state,
                loading: false,
                brandList: action.payload,
            };
        case brandAndStore.UPDATE_BRAND_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case brandAndStore.DELETE_BRAND_SUCCESS:
            const updatedBrand = state.brandList.filter(
                (brand) => brand.id !== action.id
            );
            
            return {
                ...state,
                loading: false,
                brandList: updatedBrand,
            };
        case brandAndStore.DELETE_BRAND_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }

        // Store cases
        case brandAndStore.GET_STORE_PENDING:
            return {
                ...state,
                loading: true,
            };
        case brandAndStore.GET_STORE_SUCCESS:
            return {
                ...state,
                loading: false,
                storeList: action.payload,
            };
        case brandAndStore.GET_STORE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case brandAndStore.ADD_STORE_PENDING:
            return {
                ...state,
                loading: true,
            };
        case brandAndStore.ADD_STORE_SUCCESS:
            return {
                ...state,
                loading: false,
                storeList: action.payload,
            };
        case brandAndStore.ADD_STORE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case brandAndStore.UPDATE_STORE_PENDING:
            return {
                ...state,
                loading: true,
            };
        case brandAndStore.UPDATE_STORE_SUCCESS:
            return {
                ...state,
                loading: false,
                storeList: action.payload,
            };
        case brandAndStore.UPDATE_STORE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case brandAndStore.DELETE_STORE_SUCCESS:
            const updatedStore = state.storeList.filter(
                (store) => store.id !== action.id
            );
            
            return {
                ...state,
                loading: false,
                storeList: updatedStore,
            };

        case brandAndStore.DELETE_STORE_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }

        default:
            return state;
    }
}
