import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { categoryReducer } from "./category.reducers";
import { productReducer } from "./product.reducers";
import { enquiryReducer } from "./enquiry.reducers";
import { colorReducer } from "./color.reducers";
import { emailReducer } from "./email.reducers";
import { brandAndStoreReducer } from "./brandAndStore.reducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  category: categoryReducer,
  product: productReducer,
  enquiry: enquiryReducer,
  color: colorReducer,
  email: emailReducer,
  brandAndStore: brandAndStoreReducer,
});
