import { product } from "../constants";

const initialState = {
  loading: false,
  products: [],
  productDetails: { option : []},
  error: null,
  mediaList: [],
  brandList: [],
  storeList: [],
  colorList: [],
};

export function productReducer(state = initialState, action) {
  switch (action.type) {
    case product.GET_PRODUCT_PENDING:
      return {
        ...state,
        loading: true,
      };
    case product.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
        productDetails: {},
      };
    case product.GET_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case product.ADD_PRODUCT_PENDING:
      return {
        ...state,
        loading: true,
      };
    case product.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        productDetails: {}
      };
    case product.ADD_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case product.GET_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        productDetails: action.payload
      };

    case product.GET_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case product.GET_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case product.GET_MEDIA_SUCCESS:
      return {
        ...state,
        loading: false,
        mediaList: action.payload,
      };
    case product.DELETE_MEDIA_SUCCESS: {
      const media = state.mediaList;
      media.splice(state.mediaList.findIndex((el) => el.media_id == action.payload), 1);
      return {
        ...state,
        mediaList: [...media],
        loading: false
      }
    }
    case product.DELETE_MEDIA_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }
    case product.ADD_MEDIA_SUCCESS: {
      return {
        ...state,
        loading: false,
        mediaList: [...state.mediaList, action.payload.media_details.shift()]
      }
    }
    case product.ADD_MEDIA_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }
    case product.PRIMARY_MEDIA_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case product.PRIMARY_MEDIA_SUCCESS: {
      return {
        ...state,
        loading: false,
        mediaList: state.mediaList.map((media) => {
          if (media.media_id != action.payload) {
            media.is_default = 0;
            return media
          } else {
            media.is_default = 1;
            return media;
          }
        })
      }
    }
    case product.PRIMARY_MEDIA_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case product.GET_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        brandList: action.payload,
      };

    case product.GET_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        storeList: action.payload,
      };

    case product.UPDATE_PRODUCT_FAILURE:
      return {
        ...state,

        loading: false
      };
    case product.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.map((product) =>
          product.product_id === action.payload ? { ...product, is_delete: 0 } : product
        ),
      };
    case product.GET_COLOR_SUCCESS:
      return {
        ...state,
        loading: false,
        colorList: action.payload,
      };
      
    // case product.PRIMARY_COLOR_MEDIA_SUCCESS: {
    //   const options = state.productDetails.option;
    //   options[0].vals.map((val, i) => {
    //     console.log('condition', options[0].vals[i], action.payload.image_index, typeof options[0].vals[i], typeof action.payload.image_index);
    //     if (i != action.payload.image_index) {
    //       console.log('case 1');
    //       options[0].vals[i].isPrimary = 0;
    //     } else {
    //       console.log('case 2');
    //       options[0].vals[i].isPrimary = 1;
    //     }
    //   })

    //   console.log('options', options);
    //   return {
    //     ...state,
    //     loading: false,
    //     productDetails: {
    //       option: options
    //     }
    //   }
    // }

    default:
      return state;
  }
}
