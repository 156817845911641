import { enquiry } from "../constants";

const initialState = {
  loading: false,
  enquiries: [],
  error: null,
  
};

export function enquiryReducer(state = initialState, action) {
  switch (action.type) {
    case enquiry.GET_ENQUIRY_PENDING:
      return {
        ...state,
        loading: true,
      };
    case enquiry.GET_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        enquiries: action.payload,
      };
    case enquiry.GET_ENQUIRY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
      case enquiry.DELETE_ENQUIRY_SUCCESS:
      // Update the state by removing the deleted row from the enquiries array
      const updatedEnquiries = state.enquiries.filter(
        (enquiry) => enquiry.id !== action.id
      );
      console.log(state)
      return {
        ...state,
        loading: false,
        enquiries: updatedEnquiries,
      };
      
      case enquiry.DELETE_ENQUIRY_FAILURE: {
        return {
          ...state,
          loading: false
        }
      }

    default:
      return state;
    }
}